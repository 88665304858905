<template>
  <div>
    <trac-loading v-if="loading" />
    <div class="mb-2 mx-12 mt-12">
      <div
        @click="$router.go(-1)"
        class="mb-1 cursor-pointer text-sm font-light"
      >
        &lt; Back
      </div>
      <h1 class="font-bold">Booking Setting</h1>
    </div>
    <div class="p-6 m-12 small-shadow rounded bg-white">
      <div class="border py-4 border-gray-300 rounded-md">
        <div class="flex mx-4 items-center">
          <img src="@/assets/noona-booking.png" class="w-8 h-8 mr-3" alt="" />
          <h1 class="font-bold flex-1 text-xs">
            Set up your booking settings preference
          </h1>
        </div>
        <hr class="h-px mt-4 mb-2 bg-gray-200 border-0 dark:bg-gray-700" />
        <div class="text-xs mx-4 my-4 font-bold">
          How would you want to charge for bookings?
        </div>
        <div class="grid mx-4 grid-cols-2 gap-x-5 gap-y-4">
          <div class="col-span-1">
            <!-- <span class="text-xs text-gray-600 inline-block mb-1"
              >How would you want to charge for bookings?</span
            > -->
            <trac-dropdown
              @onselect="assignPlan($event)"
              :menuItems="bookingOptions"
              :title="selectedValueLabel"
              class="w-full"
              :extra-class="'py-3'"
            ></trac-dropdown>
          </div>
          <div class="col-span-1">
            <!-- <span
              v-if="selectedValueString === 'PERCENTAGE'"
              class="text-xs text-gray-600 inline-block mb-1"
              >Enter Percentage</span
            >
            <span
              v-if="selectedValueString === 'FLAT_FEE'"
              class="text-xs text-gray-600 inline-block mb-1"
              >Enter Flat Fee</span
            >
            <span
              v-if="!selectedValueString"
              class="text-xs none text-gray-600  mb-1"
              >.</span
            > -->
            <trac-input
              v-if="selectedValueString !== 'NO_BOOKING'"
              v-model="bookingValue"
              :placeholder="selectedLabel"
              type="text"
              class=""
            />
            <span
              class="text-xs text-red-600 inline-block mb-1"
              v-if="formValid.bookingValue"
              >{{ formValid.bookingValue }}
            </span>
          </div>

          <div class="col-span-2 border h-px border-gray-200"></div>

          <div class="col-span-1">
            <span class="text-xs font-medium inline-block mb-2"
              >Customize your booking page</span
            >
            <trac-upload
              :placeholder-text="'Upload Banner'"
              @uploadedFile="assignPreview($event)"
            ></trac-upload>
            <div v-if="previewUrl !== null">
              <div class="flex my-2">
                <div class="text-xs font-medium flex-1">Banner Preview</div>
                <button @click="previewUrl = null" class="text-xs">X</button>
              </div>
              <img :src="previewUrl" class="w-full" />
            </div>
          </div>
          <div class="col-span-1">
            <span class="text-xs font-medium inline-block mb-2 text-white"
              >.</span
            >
            <trac-upload
              :placeholder-text="'Upload Logo (Optional)'"
              @uploadedFile="assignLogo($event)"
            ></trac-upload>
            <div v-if="logoUrl !== null">
              <div class="flex my-2">
                <div class="text-xs font-medium flex-1">Logo Preview</div>
                <button @click="logoUrl = null" class="text-xs">X</button>
              </div>
              <div class="p-12 bg-gray-200 rounded">
                <img :src="logoUrl" class="w-full" />
              </div>
            </div>
          </div>
          <!-- <div class="col-span-1">
            <trac-upload></trac-upload>
          </div> -->
        </div>

        <div class="col-span-2 px-4">
          <trac-button
            :disabled="!allowedToSubmit"
            class="w-auto px-12 my-8 uppercase"
            @button-clicked="setBookingPreferances()"
          >
            Save
          </trac-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../eventBus";
export default {
  data() {
    return {
      copied: false,
      loading: false,
      bookingOptions: [
        { name: "Percentage", value: "PERCENTAGE" },
        { name: "Flat Fee", value: "FLAT_FEE" },
        { name: "No Booking", value: "NO_BOOKING" },
      ],
      selectedPlan: null,
      previewUrl: null,
      bookingValue: null,
      logoUrl: null,
    };
  },
  created() {
    this.checkStatus();
  },
  computed: {
    selectedLabel() {
      if (this.selectedValueString === "FLAT_FEE") {
        return "Enter Flat Fee";
      } else if (this.selectedValueString === "PERCENTAGE") {
        return "Enter Percentage";
      } else {
        return "";
      }
    },
    selectedValueString() {
      return (this.selectedPlan || { value: null }).value || null;
    },
    selectedValueLabel() {
      return (this.selectedPlan || {name: 'Select a Plan'}).name
      // return 'Somewhat'
    },
    ifNoBookingWasSet() {
      return this.selectedValueString === "NO_BOOKING";
    },
    formValid() {
      const onlyNumbers = /^[0-9]+$/;

      let amountTest = false;
      if ((this.selectedPlan || { value: null }).value === "PERCENTAGE") {
        amountTest =
          onlyNumbers.test(this.bookingValue) &&
          this.bookingValue > 0 &&
          this.bookingValue < 101
            ? false
            : "Select a number between 0 and 100";
      } else if ((this.selectedPlan || { value: null }).value === "FLAT_FEE") {
        amountTest =
          onlyNumbers.test(this.bookingValue) && this.bookingValue > 0
            ? false
            : "Select a number greater than 0";
      }
      return {
        bookingValue: amountTest,
      };
    },
    numericCheck() {
      return Object.keys(this.formValid).every(
        (item) => this.formValid[item] === false
      );
    },
    allowedToSubmit() {
      const FlatFeeOrPercentage =
        (this.selectedValueString === "PERCENTAGE" ||
          this.selectedValueString === "FLAT_FEE") &&
        this.bookingValue;
      // if this
      if (
        (FlatFeeOrPercentage && this.numericCheck) ||
        this.ifNoBookingWasSet
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    checkStatus() {
      this.loading = true;
      this.$store
        .dispatch("NOONA_CHECK_STATUS")
        .then((data) => {
          console.log("data resolved: ", data);
          if (data.status && data.data.connected) {
            // do nothing
            this.loadPriorSettings(data.data.noona_business || [])
            this.loading = false;
          } else {
            this.$router.push({ name: "Noona" });
            this.loading = false;
          }
        })
        .catch(() => {
          // (this.loading = false),
          //   (this.message = "An error occured. Please try again");
        });
    },
    loadPriorSettings (business = []) {
      if (business.length > 0) {
        console.log('loadPrior')
        const details = business[0].booking_preference

        if(!details) return false

        if (details.banner) {
          this.previewUrl = details.banner
        }
        if (details.logo) {
          this.logoUrl = details.logo
        }
        if (details.booking_plan) {
          this.selectedPlan = this.bookingOptions.find(item => item.value === details.booking_plan)
        }
        if (details.booking_amount) {
          this.bookingValue = details.booking_amount
        }
        // debugger
      }
    },
    assignPlan($event) {
      this.selectedPlan = $event;
    },
    assignPreview($url) {
      this.previewUrl = $url;
    },
    assignLogo($url) {
      this.logoUrl = $url;
    },
    setBookingPreferances() {
      this.loading = true;
      const payload = {
        booking_plan: this.selectedPlan.value,
        booking_amount: parseInt(this.bookingValue),
      };
      if (this.previewUrl) {
        payload.banner = this.previewUrl;
      }
      if (this.logoUrl) {
        payload.logo = this.logoUrl
      }
      // debugger
      this.$store.dispatch("NOONA_PREFERENCES", payload).then((data) => {
        if (data.status) {
          this.loading = false;
          eventBus.$emit("trac-alert", {
            message: data.message,
          });
          this.$TrackEvent("TA_MERCHANT_APP_NOONA_SET_PREFERENCE");
          this.$router.push({ name: "Appointments" });
        } else {
          this.loading = false;
          eventBus.$emit("trac-alert", {
            message: data.message,
          });
        }
      });
    }
  },
};
</script>

<style lang="postcss" scoped>
.trim-box {
  background: #f6fcff;
  border: 0.3px dashed #253b95;
  border-radius: 5px;
}
</style>